<template>
    <v-app style="background-color: #191B20;">
        <v-container class="my-3 mx-xs-0">
            <v-row>
                <v-img
                class="my-4"
                lazy-src="https://picsum.photos/id/11/10/6"
                max-height="64"
                max-width="100"
                src="@/assets/images/logo1.svg"
                ></v-img>
            </v-row>
            <v-row class="mt-12">
                <p class="text-h4 yellow--text text--darken-3">ตั้งรหัสผ่านใหม่</p>
            </v-row>
            <v-row>
                <p class="text-subtitle-1 white--text">กรุณาสร้างรหัสผ่านใหม่</p>
            </v-row>
            <v-row>
                <v-form v-model="isValid" ref="form" class="w-400px">
                    <v-text-field
                        dark
                        color="yellow darken-3"
                        label="ชื่อผู้ใช้งาน"
                        outlined
                        dense
                        disabled
                        v-model="username"
                    ></v-text-field>
                    <v-text-field
                        dark
                        color="yellow darken-3"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                        label="รหัสผ่านใหม่"
                        outlined
                        dense
                        :type="showPassword ? 'text' : 'password'"
                        v-model="password"
                        counter="20"
                        :rules="rulesPassword"
                    ></v-text-field>
                    <v-text-field
                        dark
                        color="yellow darken-3"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                        label="ยืนยันรหัสผ่าน"
                        outlined
                        dense
                        :type="showPassword ? 'text' : 'password'"
                        v-model="confirmPassword"
                        :rules="[v => v == password || 'รหัสผ่านไม่ตรงกัน']"
                    ></v-text-field>
                    <v-row>
                        <v-col cols="12">
                            <v-btn color="yellow darken-3" @click="submit" class="w-400px">ยืนยัน</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-row>
        </v-container>
        <alert-error :text="errorMessage" ref="alertError" />
        <alert-success :text="successMessage" :redirectTo="{ name: 'Login'}" ref="alertSuccess" />
    </v-app>
</template>

<script>
import AlertError from '../components/AlertError.vue';
import AlertSuccess from '../components/AlertSuccess.vue';
import axios from 'axios';

export default {
    data: () => ({
        showPassword: false,
        token: '',
        username: '',
        password: '',
        confirmPassword: '',
        rules: {
            confirmPassword: v => v == this.password || 'รหัสผ่านไม่ตรงกัน'
        },
        rulesPassword: [
            value => !!value || 'โปรดใส่รหัสผ่าน',
            value => value.length >= 8 || 'ความยาวของรหัสผ่านอย่างน้อย 8 ตัวอักษร',
            value => value.length <= 20 || 'รหัสผ่านเกิน 20 ตัวอักษร'
        ],
        isValid: false,
        errorMessage: '',
        successMessage: ''
    }),
    methods: {
        async submit(){
            this.$refs.form.validate();
            this.errorMessage = '';
            if(this.isValid){
                const loader = this.$loading.show();
                try{
                    await axios.post(process.env.VUE_APP_BASE_URL + '/forgot-password/new?token=' + this.token, {
                        username: this.username,
                        newPassword: this.password,
                        confirmPassword: this.confirmPassword
                    });
                    this.successMessage = 'เปลี่ยนรหัสผ่านเรียบร้อยแล้ว ';
                    this.$refs.alertSuccess.show();
                }catch(error){
                    this.errorMessage = error.response?.data[0]?.msg || error.response?.data?.fail || error.message;
                    this.$refs.alertError.show();
                }finally{
                    loader.hide();
                }
                console.log('submit');
            }
        }
    },
    mounted(){
        this.username = this.$route.query?.username || '';
        this.token = this.$route.query?.token || '';
    },
    components: {
        AlertError,
        AlertSuccess
    }
};
</script>

<style scoped>
    .w-400px{
        width: 400px;
    }
</style>